import React, { useContext, useEffect, useState } from 'react';
import Select from '../../components/forms/Select/Select';
import Table from '../../components/Table/Table';
import { getRegions } from '../../services/catalog/catalog';
import { getSchoolResultsStatistics } from '../../services/schoolStatistics/schoolStatistics';
import { UserContext } from '../../utils/contexts/UserContext';
import ExportExcelExamStats from './ExportExcelExamStats';
import { ExamYearContext } from '../../utils/contexts/ExamYearContext';
import SimpleLoader from '../../components/Loader/SimpleLoader';

const TWOROUNDEXAM = [
  {
    Header: 'ID',
    accessor: 'school_id',
  },
  {
    Header: 'Название',
    accessor: 'name',
    width: 240,
    // Cell: (props) => <span className='w-40'>{props.value}</span>,
  },
  {
    Header: 'Участвовали в 1 туре (Регион)',
    accessor: 'first_round_participated_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали в 1 туре (Центр)',
    accessor: 'first_round_participated_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали в 1 туре (Всего)',
    accessor: 'first_round_participated_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 1 туре (Регион)',
    accessor: 'first_round_passed_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 1 туре (Центр)',
    accessor: 'first_round_passed_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 1 туре (Всего)',
    accessor: 'first_round_passed_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали в 2 туре (Регион)',
    accessor: 'second_round_participated_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали в 2 туре (Центр)',
    accessor: 'second_round_participated_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали в 2 туре (Всего)',
    accessor: 'second_round_participated_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 2 туре (Регион)',
    accessor: 'second_round_passed_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 2 туре (Центр)',
    accessor: 'second_round_passed_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли 2 туре (Всего)',
    accessor: 'second_round_passed_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
];

const ONEROUNDEXAM = [
  {
    Header: 'ID',
    accessor: 'school_id',
  },
  {
    Header: 'Название',
    accessor: 'name',
    width: 240,
    // Cell: (props) => <span className='w-40'>{props.value}</span>,
  },
  {
    Header: 'Участвовали (Регион)',
    accessor: 'first_round_participated_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали (Центр)',
    accessor: 'first_round_participated_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Участвовали (Всего)',
    accessor: 'first_round_participated_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли (Регион)',
    accessor: 'first_round_passed_region',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли (Центр)',
    accessor: 'first_round_passed_center',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
  {
    Header: 'Прошли (Всего)',
    accessor: 'first_round_passed_total',
    Cell: (props) => <div className='text-center'>{props.value}</div>,
  },
];

const SchoolExamStatistics = () => {
  const { user } = useContext(UserContext);
  const { examYear } = useContext(ExamYearContext);

  const [schoolStatistics, setSchoolStatistics] = useState([]);
  const [numbersByAllSchools, setNumbersByAllSchools] = useState({
    first_round_participated_region: 0,
    first_round_participated_center: 0,
    first_round_participated_total: 0,
    first_round_passed_region: 0,
    first_round_passed_center: 0,
    first_round_passed_total: 0,
    second_round_participated_region: 0,
    second_round_participated_center: 0,
    second_round_participated_total: 0,
    second_round_passed_region: 0,
    second_round_passed_center: 0,
    second_round_passed_total: 0,
  });

  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (examYear?.uuid) {
      console.log('first', examYear);
      setLoading(true);
      const fetchData = async () => {
        try {
          await getSchoolResultsStatistics({
            exam_year: examYear?.uuid,
            page_size: 50,
          })
            .then((res) => {
              setSchoolStatistics(res);
              if (examYear?.year === 2023) {
                let first_round_participated_region = 0;
                let first_round_participated_center = 0;
                let first_round_participated_total = 0;
                let first_round_passed_region = 0;
                let first_round_passed_center = 0;
                let first_round_passed_total = 0;
                let second_round_participated_region = 0;
                let second_round_participated_center = 0;
                let second_round_participated_total = 0;
                let second_round_passed_region = 0;
                let second_round_passed_center = 0;
                let second_round_passed_total = 0;

                res.forEach((school) => {
                  first_round_participated_region +=
                    school?.first_round_participated_region;
                  first_round_participated_center +=
                    school?.first_round_participated_center;
                  first_round_participated_total +=
                    school?.first_round_participated_total;
                  first_round_passed_region +=
                    school?.first_round_passed_region;
                  first_round_passed_center +=
                    school?.first_round_passed_center;
                  first_round_passed_total += school?.first_round_passed_total;
                  second_round_participated_region +=
                    school?.second_round_participated_region;
                  second_round_participated_center +=
                    school?.second_round_participated_center;
                  second_round_participated_total +=
                    school?.second_round_participated_total;
                  second_round_passed_region +=
                    school?.second_round_passed_region;
                  second_round_passed_center +=
                    school?.second_round_passed_center;
                  second_round_passed_total +=
                    school?.second_round_passed_total;
                });

                setNumbersByAllSchools({
                  first_round_participated_region,
                  first_round_participated_center,
                  first_round_participated_total,
                  first_round_passed_region,
                  first_round_passed_center,
                  first_round_passed_total,
                  second_round_participated_region,
                  second_round_participated_center,
                  second_round_participated_total,
                  second_round_passed_region,
                  second_round_passed_center,
                  second_round_passed_total,
                });
              } else {
                let first_round_participated_region = 0;
                let first_round_participated_center = 0;
                let first_round_participated_total = 0;
                let first_round_passed_region = 0;
                let first_round_passed_center = 0;
                let first_round_passed_total = 0;

                res.forEach((school) => {
                  first_round_participated_region +=
                    school?.first_round_participated_region;
                  first_round_participated_center +=
                    school?.first_round_participated_center;
                  first_round_participated_total +=
                    school?.first_round_participated_total;
                  first_round_passed_region +=
                    school?.first_round_passed_region;
                  first_round_passed_center +=
                    school?.first_round_passed_center;
                  first_round_passed_total += school?.first_round_passed_total;
                });

                setNumbersByAllSchools({
                  first_round_participated_region,
                  first_round_participated_center,
                  first_round_participated_total,
                  first_round_passed_region,
                  first_round_passed_center,
                  first_round_passed_total,
                });
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {}
      };

      fetchData();

      getRegions(examYear?.uuid).then((res) => {
        setRegions(res.results);
      });
    }

    return () => {};
  }, [examYear]);

  const columns = React.useMemo(
    () => (examYear?.year === 2023 ? TWOROUNDEXAM : ONEROUNDEXAM),
    [examYear],
  );

  return !loading ? (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row justify-end gap-2'>
        <div>
          Участвовали в первом туре(Регион):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_participated_region}
          </span>
        </div>
        <div>
          Участвовали в первом туре(Центр):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_participated_center}
          </span>
        </div>
        <div>
          Участвовали в первом туре(Всего):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_participated_total}
          </span>
        </div>
      </div>
      <div className='flex flex-row justify-end gap-2'>
        <div>
          Прошли первый тур(Регион):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_passed_region}
          </span>
        </div>
        <div>
          Прошли первый тур(Центр):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_passed_center}
          </span>
        </div>
        <div>
          Прошли первый тур(Всего):{' '}
          <span className='font-bold'>
            {numbersByAllSchools?.first_round_passed_total}
          </span>
        </div>
      </div>

      {numbersByAllSchools?.second_round_participated_total && (
        <div className='flex flex-row justify-end gap-2'>
          <div>
            Участвовали в втором туре(Регион):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_participated_region}
            </span>
          </div>
          <div>
            Участвовали в втором туре(Центр):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_participated_center}
            </span>
          </div>
          <div>
            Участвовали в втором туре(Всего):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_participated_total}
            </span>
          </div>
        </div>
      )}
      {numbersByAllSchools?.second_round_passed_total && (
        <div className='flex flex-row justify-end gap-2'>
          <div>
            Прошли второй тур(Регион):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_passed_region}
            </span>
          </div>
          <div>
            Прошли второй тур(Центр):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_passed_center}
            </span>
          </div>
          <div>
            Прошли второй тур(Всего):{' '}
            <span className='font-bold'>
              {numbersByAllSchools?.second_round_passed_total}
            </span>
          </div>
        </div>
      )}
      <div className='flex flex-row justify-between'>
        <Select
          className={`invisible h-6 w-48 ${
            user?.roles?.some((role) => role?.code.includes('RC')) && 'hidden'
          }`}
          placeholder={'Регион'}
          options={[
            { label: 'Все регионы', value: '' },
            ...regions.map((region, i) => ({
              label: region?.name,
              value: region?.uuid,
            })),
          ]}
          value={region}
          onChange={(e) => {
            // setSelectedDistrict(e);
            setRegion(e.value);
            getSchoolResultsStatistics({
              exam_year: examYear?.uuid,
              page_size: 50,
              region: e.value,
            }).then((res) => {
              setSchoolStatistics(res);
              let first_round_participated_region = 0;
              let first_round_participated_center = 0;
              let first_round_participated_total = 0;
              let first_round_passed_region = 0;
              let first_round_passed_center = 0;
              let first_round_passed_total = 0;
              let second_round_participated_region = 0;
              let second_round_participated_center = 0;
              let second_round_participated_total = 0;
              let second_round_passed_region = 0;
              let second_round_passed_center = 0;
              let second_round_passed_total = 0;

              res.forEach((school) => {
                first_round_participated_region +=
                  school?.first_round_participated_region;
                first_round_participated_center +=
                  school?.first_round_participated_center;
                first_round_participated_total +=
                  school?.first_round_participated_total;
                first_round_passed_region += school?.first_round_passed_region;
                first_round_passed_center += school?.first_round_passed_center;
                first_round_passed_total += school?.first_round_passed_total;
                second_round_participated_region +=
                  school?.second_round_participated_region;
                second_round_participated_center +=
                  school?.second_round_participated_center;
                second_round_participated_total +=
                  school?.second_round_participated_total;
                second_round_passed_region +=
                  school?.second_round_passed_region;
                second_round_passed_center +=
                  school?.second_round_passed_center;
                second_round_passed_total += school?.second_round_passed_total;
              });

              setNumbersByAllSchools({
                first_round_participated_region,
                first_round_participated_center,
                first_round_participated_total,
                first_round_passed_region,
                first_round_passed_center,
                first_round_passed_total,
                second_round_participated_region,
                second_round_participated_center,
                second_round_participated_total,
                second_round_passed_region,
                second_round_passed_center,
                second_round_passed_total,
              });
            });
          }}
        />
        <ExportExcelExamStats params={{ region, exam_year: examYear?.uuid }} />
      </div>
      <Table columns={columns} data={schoolStatistics} />
    </div>
  ) : (
    <SimpleLoader className='h-16' />
  );
};

export default SchoolExamStatistics;
